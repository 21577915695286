import React from 'react'
import classnames from 'classnames'

import stepInfo from './steps-info.json'
/* eslint-disable max-len */
function HowItWorks() {
  return (
    <div className="flex flex-col py-8">
      <h2 className="font-serif self-center text-xl md:text-3xl 2xl:text-5xl">
        Here’s how it works
      </h2>
      <h3
        className="font-sans text-sm 2xl:text-lg tracking-widest uppercase text-center pt-8 pb-2 font-medium"
      >
        Base Pricing Includes:
      </h3>
      <div className="font-sans text-sm 2xl:text-lg tracking-widest text-center">
        <span className="font-sans italic text-sm 2xl:text-lg tracking-normal">up to</span>
        {' '}
        <span className="font-sans font-bold italic text-sm 2xl:text-lg tracking-normal">waist length</span>
        {' '}
        <span className="font-serif text-med 2xl:text-xlg tracking-wider">STYLE OPTION, BRAID HAIR & PRODUCT</span>
        {'   •   '}
        <span className="font-sans font-bold text-med 2xl:text-xlg uppercase tracking-wider">iPad Entertainment</span>
        {'   •   '}
        <span className="font-serif text-med 2xl:text-xlg">MIMOSAS</span>
        {'   •   '}
        <span className="font-sans font-bold text-med 2xl:text-xlg uppercase tracking-wider">Peace of Mind</span>
      </div>
      <div className="flex flex-col md:flex-row">
        {
          stepInfo.map((info) => (
            <div
              key={info.step}
              className="py-4 px-8 w-full md:w-1/3"
            >
              <img
                className={classnames('w-full max-h-[450px] 2xl:max-h-[650px] object-cover', { 'object-top': info.imgTop })}
                src={info.image}
                alt=""
              />
              <div className="flex mt-2">
                <h3 className="font-sans text-6xl md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-7xl 3xl:text-8xl font-bold flex flex-col items-center">
                  {info.step}
                  <div className="h-[150px] md:h-[230px] 2xl:h-[350px] border border-solid border-light-gray left-1/2" />
                </h3>
                <div className="ml-4 mt-6 md:mt-2">
                  <h4 className="flex">
                    <span className="font-sans font-semibold self-start mr-1 text-lg lg:text-xl 2xl:text-2xl 3xl:text-3xl">
                      {`${info.boldHeader} ${info.lowerHeader}`}
                    </span>
                  </h4>
                  <div className="text-med w-[60%] md:w-full lg:w-4/5 whitespace-pre-wrap mt-4 font-sans font-normal text-med 2xl:text-lg 3xl:text-xl">
                    {info.copy}
                  </div>
                </div>
              </div>
            </div>
          ))
        }
      </div>
    </div>
  )
}
/* eslint-enable max-len */

export default HowItWorks
