import React, { useRef, useEffect } from 'react'
import PropType from 'prop-types'
/* eslint-disable import/no-unresolved */
import { register } from 'swiper/element/bundle'
import 'swiper/css/thumbs'
import 'swiper/css'
/* eslint-enable import/no-unresolved */

import Slide from './Slide'

register()

function Carousel({ photos }) {
  const swiperRef = useRef(null)

  useEffect(() => {
    const swiperParams = {
      slidesPerView: 4.3,
      breakpoints: {
        1: {
          slidesPerView: 1.3,
        },
        512: {
          slidesPerView: 2.3,
        },
        820: {
          slidesPerView: 3.3,
        },
        1024: {
          slidesPerView: 4.3,
        },
        1200: {
          slidesPerView: 5.3,
        },
      },
      on: {
        init() { },
      },
    }

    Object.assign(swiperRef.current, swiperParams)

    swiperRef.current.initialize()
  }, [])

  return (
    <div>
      <swiper-container
        init="false"
        navigation="true"
        ref={swiperRef}
      >
        {
          photos.map((photo) => (
            <Slide
              key={photo.id}
              photo={photo}
            />
          ))
        }
      </swiper-container>
    </div>
  )
}

Carousel.propTypes = {
  photos: PropType.array.isRequired,
}

export default Carousel
