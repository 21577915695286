import React from 'react'
import { createRoot } from 'react-dom/client'

import App from './components/App'

// Update this to use just an App and not multiple components
document.addEventListener('DOMContentLoaded', () => {
  const elems = $('div[data-react-class]')
  const elem = elems[0]
  const props = $(elem).data('reactProps')
  const node = React.createElement(App, props)
  const root = createRoot(elem)
  root.render(node)
})
