import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { useAsyncCallback } from 'react-async-hook'

import PulseBox from './PulseBox'
import { MD_BREAKPOINT, SM_BREAKPOINT, XXS_BREAKPOINT } from './constants'

const NUM_LG_BOXES = 4
const NUM_MD_BOXES = 3
const NUM_SM_BOXES = 2
const NUM_XXS_BOXES = 1

function InfiniteScroll({ last, loadMore }) {
  const toLoadRef = useRef(null)
  const [firstLoad, setFirstLoad] = useState(true)
  const [prevY, setPrevY] = useState(0)
  const windowSize = useRef(window.innerWidth)

  const numBoxes = () => {
    if (windowSize.current < MD_BREAKPOINT && windowSize.current >= SM_BREAKPOINT) {
      return NUM_MD_BOXES
    } if (windowSize.current < SM_BREAKPOINT && windowSize.current >= XXS_BREAKPOINT) {
      return NUM_SM_BOXES
    } if (windowSize.current < XXS_BREAKPOINT) {
      return NUM_XXS_BOXES
    }
    return NUM_LG_BOXES
  }

  const handleLoadMore = async (entries) => {
    const currentY = entries[0].boundingClientRect.y
    if (currentY < prevY) {
      await loadMore()
    }

    setPrevY(currentY)
  }

  const handleAsyncLoadMore = useAsyncCallback(handleLoadMore)

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false)
      return () => { }
    }
    const options = {
      root: null,
      rootMargin: '200px',
      threshold: 1.0,
    }
    const observer = new IntersectionObserver(handleAsyncLoadMore.execute, options)
    if (toLoadRef.current) {
      observer.observe(toLoadRef.current)
    }

    return () => {
      if (toLoadRef.current) {
        observer.unobserve(toLoadRef.current)
      }
    }
  }, [handleAsyncLoadMore.execute, toLoadRef.current])

  /* eslint-disable react/no-array-index-key */
  if (!last) {
    return (
      <div className="min-w-full min-h-[300px] flex" ref={toLoadRef}>
        {[...Array(numBoxes())].map((e, i) => <PulseBox key={i} />)}
      </div>
    )
  }
  /* eslint-enable react/no-array-index-key */

  return null
}

InfiniteScroll.propTypes = {
  last: PropTypes.bool.isRequired,
  loadMore: PropTypes.func.isRequired,
}

export default InfiniteScroll
