import React, { useState } from 'react'
import Marquee from 'react-fast-marquee'
import { useAsync } from 'react-async-hook'
import { Link } from 'react-router-dom'

import DividerBraidsSlimImg from 'images/divider-braids-slim.png'
import NatanyaSignatureImg from 'images/natanya-signature.png'
import BannerTexts from './BannerTexts'
import Carousel from './Carousel'
import { api, isLoading } from '../../../services'
import routes from '../services/routes'
import HowItWorks from './HowItWorks'

import copy from './copy.json'
import BookButton from './BookButton'
import Footer from '../Footer'

function Homepage() {
  const [styles, setStyles] = useState([])

  const getStyles = async () => {
    const query = { shallow: true, page: 1, items: 7 }
    const { data } = await api.styles.index(query)
    setStyles(data.styles)
  }

  const { status: stylesStatus } = useAsync(getStyles)

  const photos = () => styles.map((style) => {
    const { featured_photo: featuredPhoto } = style
    return {
      ...featuredPhoto,
      styleName: style.name,
      styleId: style.id,
    }
  })

  return (
    <div className="relative">
      {/* eslint-disable max-len */}
      <div className="border-t-2 border-solid border-orange md:border-none h-fit naza-bg-black md:bg-none justify-center z-10 flex md:block p-8 md:p-0 w-full md:w-auto fixed md:absolute bottom-0 md:bottom-none md:top-[-52px] md:right-[10px]">
        <BookButton small />
      </div>
      {/* eslint-enable max-len */}
      <div className="relative">
        <picture>
          <source
            media="(max-width: 767px)"
            srcSet="https://projectcurl-assets.s3.amazonaws.com/NewHomepage/naza-hero.png"
          />
          <source
            media="(min-width: 768px)"
            srcSet="https://projectcurl-assets.s3.amazonaws.com/NewHomepage/resized_extended_hero.png"
          />
          <img
            className="w-full h-[calc(100vh-9rem)] md:h-[95vh] object-cover"
            src="https://projectcurl-assets.s3.amazonaws.com/NewHomepage/resized_extended_hero.png"
            alt="Hero showing box braids"
          />
        </picture>
        <h1 className="font-sans text-white text-[42vw] absolute bottom-[-19vw] uppercase font-semibold">Naza</h1>
      </div>
      <div className="w-full">
        <div className="bg-brown w-full pt-32 md:pt-48 2xl:pt-60 4xl:pt-72 pb-16">
          <Marquee
            gradient={false}
            className="bg-black"
            speed="30"
          >
            <BannerTexts />
          </Marquee>
        </div>
      </div>
      {
        !isLoading(stylesStatus) && (
          <div className="naza-bg-black pb-10 flex flex-col items-center">
            <div className="w-full pt-10 pb-8 text-center">
              <h2 className="text-white font-serif text-2xl md:text-3xl mx-[2vw] tracking-wide">
                We’ve Got Style(s)
              </h2>
              <h3 className="text-white font-serif pt-2 pb-8 md:pb-12 text-lg md:text-xlg px-1">
                Our collection of styles is
                {' '}
                <span className="font-serif text-lg md:text-xlg">CURATED</span>
                {' '}
                by us,
                {' '}
                <span className="font-sans font-semibold text-lg md:text-xlg italic">CUSTOMIZED</span>
                {' '}
                by
                {' '}
                <span className="font-sans font-semibold text-lg md:text-xlg">YOU</span>
              </h3>
              <Link
                to={routes.styles.index()}
                className="font-sans text-white uppercase font-normal mr-4 text-med md:text-lg underline mt-2"
              >
                view our styles
              </Link>
            </div>
            <div className="w-full mb-8">
              <Carousel
                photos={photos()}
              />
            </div>
            <div className="font-serif text-xl lg:text-3xl text-white text-center max-w-screen-lg py-12 px-12 lg:px-0">
              Naza Beauty is your destination for everything coily, kinky, afro-textured hair.
            </div>
          </div>
        )
      }
      <div className="relative">
        <img
          className="w-full object-cover min-h-[600px] md:h-[110vh]"
          alt="Woman getting her hair washed"
          src="https://projectcurl-assets.s3.amazonaws.com/NewHomepage/science-image.png"
        />
        <div className="text-white absolute left-16 bottom-8 max-w-screen-sm 2xl:max-w-screen-med">
          {/* eslint-disable max-len */}
          <div
            className="font-serif text-xl xs:text-2xl md:text-3xl 2xl:text-4xl max-w-screen-xs 2xl:max-w-screen-sm mb-4 2xl:mb-10 w-[80%]"
          >
            Our artists have it down to a science.
          </div>
          <div className="font-sans text-lg xs:text-lg lg:text-xlg 2xl:text-xl font-normal mb-8 2xl:mb-4 leading-7 xs:leading-8 lg:leading-9 2xl:leading-tight w-[80%] xl:w-[90%]">
            {/* eslint-enable max-len */}
            Our expert stylists are specifically trained in the care, keeping,
            {' '}
            and conversations around coily, kinky, and afro-textured hair types (3C - 4C).
            {' '}
            Years of experience help them apply tried and true (and new!) techniques to make your style uniquely you.
          </div>
          <div className="hidden md:block pt-8 md:pt-12 lg:pt-14 pb-12 md:pb-16 lg:pb-20 2xl:pb-16">
            <BookButton />
          </div>
        </div>
      </div>
      <div className="flex w-full mt-[-2px]">
        <img
          alt=""
          className="w-2/3"
          src="https://projectcurl-assets.s3.amazonaws.com/NewHomepage/snaps.png"
        />
        <img
          alt=""
          className="w-1/3"
          src="https://projectcurl-assets.s3.amazonaws.com/NewHomepage/hair-wash.png"
        />
      </div>
      <div className="bg-brown h-[25px] w-full" />
      <img className="w-full" src={DividerBraidsSlimImg} alt="" />
      <HowItWorks />
      <div className="bg-brown h-[25px] w-full" />
      <div className="flex flex-col md:flex-row">
        <img
          className="w-full md:w-1/2 object-cover h-[600px] md:h-auto"
          src="https://projectcurl-assets.s3.amazonaws.com/NewHomepage/black-white-chairs.png"
          alt=""
        />
        <div className="w-full md:w-1/2 naza-bg-black p-8 text-white flex flex-col items-center">
          <div className="w-3/4 mt-24 md:max-w-[380px] 2xl:max-w-[600px]">
            <h2 className="font-serif text-2xl 2xl:text-3xl 3xl:text-5xl mb-8 2xl:mb-16">
              We don’t think your hair is difficult
            </h2>
            <p className="font-sans text-lg text-normal 2xl:text-xl 3xl:text-2xl mb-8 2xl:mb-16">
              {copy.notDifficult}
            </p>
            <div className="hidden md:block">
              <BookButton />
            </div>
          </div>
        </div>
      </div>
      <div>
        <img className="w-full h-6 sm:h-auto xl:h-16 object-cover" src={DividerBraidsSlimImg} alt="" />
        <Marquee
          gradient={false}
          className="bg-orange text-white p-2 font-sans font-normal text-lg xxs:text-xlg xs:text-xl 2xl:text-2xl"
          speed="30"
        >
          <span className="font-serif text-lg xxs:text-xlg xs:text-xl 2xl:text-2xl pr-2 font-semibold">
            WE LOVE EVERY KINK, COIL, KITCHEN, AND CURL
          </span>
          your beauty our pleasure
        </Marquee>
        <img className="w-full h-6 sm:h-auto xl:h-16 object-cover" src={DividerBraidsSlimImg} alt="" />
      </div>
      <div className="flex flex-col md:flex-row relative">
        <img
          className="w-full object-cover h-[350px] xxs:h-auto"
          src="https://projectcurl-assets.s3.amazonaws.com/NewHomepage/front-seating.png"
          alt=""
        />
        {/* eslint-disable max-len */}
        <div className="absolute w-full h-full xs:h-fit xs:w-112 md:w-144 2xl:w-168 xs:left-10 xs:bottom-10 sm:left-16 sm:bottom-16 2xl:left-24 2xl:bottom-24 bg-black bg-opacity-50 border border-yellow border-solid p-4">
          <div className="font-serif text-white leading-none xxs:leading-tight text-xl xxs:text-2xl md:text-3xl 2xl:text-4xl mb-4 xxs:mb-10 xs:mb-6 md:mb-8 2xl:mb-12">
            Intentionally designed with
            {' '}
            <span className="font-sans text-xl xxs:text-2xl md:text-3xl 2xl:text-4xl font-medium italic">US</span>
            {' '}
            in mind.
          </div>
          <div className="font-sans text-white text-lg md:text-xlg 2xl:text-xl font-normal">
            {copy.intentionalSpace}
          </div>
        </div>
      </div>
      {/* eslint-disable max-len */}
      <div className="flex flex-col md:flex-row">
        <img
          className="w-full md:w-1/2 object-cover"
          src="https://projectcurl-assets.s3.amazonaws.com/NewHomepage/drinks-pink.png"
          alt=""
        />
        <div className="w-full md:w-1/2 bg-yellow p-8 text-white">
          <div className="h-full flex flex-col items-center justify-center">
            {/* eslint-disable max-len */}
            <div className="max-w-[330px] 3xl:max-w-[350px] font-serif w-7/12 md:w-3/4 mt-4 md:mt-10 mb-4 text-2xl xs:text-3xl lg:text-4xl 2xl:text-5xl 3xl:text-6xl text-center">
              Obsessed with your delight —that’s the Naza way
            </div>
            {/* eslint-enable max-len */}
            <div className="pt-16 hidden md:block">
              <BookButton blackBorder />
            </div>
          </div>
        </div>
      </div>
      <div>
        <img className="w-full h-8 sm:h-12 object-cover" src={DividerBraidsSlimImg} alt="" />
        <div className="relative">
          <img
            className="w-full h-88 xs:h-48 md:h-64 lg:h-72 xl:h-96 object-cover"
            src="https://projectcurl-assets.s3.amazonaws.com/NewHomepage/naza-logo-repeat-orange.png"
            alt=""
          />
          <div className="absolute top-8 md:top-10 text-white w-full text-center">
            <h2 className="font-serif text-2xl md:text-3xl xl:text-4xl pb-2 lg:pb-2">Hot Off The Press</h2>
            <h3 className="font-sans text-med md:text-lg xl:text-xlg font-normal">Read about us all over the place</h3>
            {/* eslint-disable max-len */}
            <div
              className="flex-col xs:flex-row px-2 xl:px-8 pt-10 xs:pt-8 lg:pt-12 2xl:pt-16 h-60 xs:h-auto flex justify-between items-center"
            >
              {/* eslint-enable max-len */}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.elle.com/beauty/hair/a31076096/naza-san-francisco-hair-salon-natanya-montgomery-alexis-ohanian/"
              >
                <img
                  className="h-12 xs:h-6 sm:h-10 md:h-12 lg:h-auto 2xl:h-24"
                  src="https://projectcurl-assets.s3.amazonaws.com/NewHomepage/elle-logo.png"
                  alt="Elle logo"
                />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.vogue.com/article/naza-beauty-new-san-francisco-salon"
              >
                <img
                  className="h-12 xs:h-6 sm:h-10 md:h-12 lg:h-auto 2xl:h-24"
                  src="https://projectcurl-assets.s3.amazonaws.com/NewHomepage/vogue-logo.png"
                  alt="Vogue logo"
                />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.elle.com/beauty/hair/a31076096/naza-san-francisco-hair-salon-natanya-montgomery-alexis-ohanian/"
              >
                <img
                  className="h-8 xs:h-4 sm:h-6 lg:h-[43px] 2xl:h-16"
                  src="https://projectcurl-assets.s3.amazonaws.com/NewHomepage/techcrunch-logo.png"
                  alt="Techcrunch logo"
                />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://medium.com/allraise/natanya-montgomery-naza-beauty-7ad68a8a725e"
              >
                <img
                  className="h-12 xs:h-6 sm:h-10 md:h-12 lg:h-auto 2xl:h-24"
                  src="https://projectcurl-assets.s3.amazonaws.com/NewHomepage/allraise-logo.png"
                  alt="Allraise logo"
                />
              </a>
            </div>
          </div>
        </div>
        <img className="w-full h-8 sm:h-12 object-cover" src={DividerBraidsSlimImg} alt="" />
      </div>
      <img
        className="w-full"
        src="https://projectcurl-assets.s3.amazonaws.com/NewHomepage/free-baby-hairs.png"
        alt="Natanya Montgomery"
      />
      <div className="bg-black p-8 text-white flex flex-col items-center">
        <div className="max-w-[1120px]">
          <div className="w-full text-white font-serif text-xl md:text-3xl pb-8">
            Meet Our Founder 🙌🏾
          </div>
          <p
            className="border-l-2 border-solid border-yellow font-serif text-xlg md:text-2xl py-4 pl-8 w-4/5"
          >
            {copy.founderPullquote}
          </p>
          <div className="text-lg md:text-xl font-sans py-8 whitespace-pre-wrap">
            <span className="text-lg md:text-xl font-sans font-bold">Hi — I’m Natanya, founder and CEO of Naza Beauty.</span>
            <div className="inline">{` ${copy.founder}`}</div>
            <br />
            <br />
            <p className="text-lg md:text-xl font-sans font-medium flex">
              Love,
              <img
                className="inline self-end pl-2"
                src={NatanyaSignatureImg}
                alt="Natanya's Signature"
              />
            </p>
          </div>
        </div>
      </div>
      <div className="flex">
        <img
          className="w-1/3 object-cover"
          src="https://projectcurl-assets.s3.amazonaws.com/NewHomepage/baby-natanya.png"
          alt="Baby Natanya"
        />
        <img
          className="w-1/3 object-cover"
          src="https://projectcurl-assets.s3.amazonaws.com/NewHomepage/toddler-natanya.png"
          alt="Toddler Natanya"
        />
        <img
          className="w-1/3 object-cover"
          src="https://projectcurl-assets.s3.amazonaws.com/NewHomepage/pre-teen-natanya.png"
          alt="Pre-teen Natanya"
        />
      </div>
      {/* eslint-disable max-len */}
      <div
        className="naza-bg-black bg-cover text-white text-lg xs:text-xlg md:text-2xl lg:text-3xl text-center whitespace-pre bg-repeat-y bg-light-black font-sans font-bold py-16"
      >
        {copy.manifesto}
      </div>
      {/* eslint-enable max-len */}
      <div className="bg-brown h-[25px] w-full" />
      <img className="w-full" src={DividerBraidsSlimImg} alt="" />
      <Footer />
    </div>
  )
}

export default Homepage
