import React from 'react'

import InstagramIcon from 'images/icons/instagram-icon.png'
import TwitterIcon from 'images/icons/twitter-icon.png'

function Footer() {
  /* eslint-disable max-len */
  return (
    <div className="w-full bg-dark-blue text-white  flex flex-col items-center text-med xl:text-lg 2xl:text-xlg pt-12 pb-32 px-6 xs:px-12 sm:pb-12">
      {/* eslint-enable max-len */}
      <a
        className="pb-4 font-sans"
        target="_blank"
        rel="noopener noreferrer"
        href="tel:4158547111"
      >
        (415) 854-7111
      </a>
      <a
        className="pb-4 font-sans"
        target="_blank"
        rel="noopener noreferrer"
        href="mailto:booking@nazabeauty.com"
      >
        booking@nazabeauty.com
      </a>
      <a
        className="pb-4 font-sans flex items-center"
        target="_blank"
        rel="noopener noreferrer"
        href="https://twitter.com/naza_beauty"
      >
        <img className="pr-2" src={TwitterIcon} alt="" />
        @naza_beauty
      </a>
      <a
        className="pb-4 font-sans flex items-center"
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.instagram.com/hellonazabeauty/"
      >
        <img className="pr-2" src={InstagramIcon} alt="" />
        @hellonazabeauty
      </a>
      <a
        className="pb-4 font-sans"
        target="_blank"
        rel="noopener noreferrer"
        href="https://goo.gl/maps/fgU4fpSN1pAjxuAt8"
      >
        985 Valencia St., San Francisco, CA 94110
      </a>
    </div>
  )
}

export default Footer
