import React from 'react'
import { createBrowserRouter } from 'react-router-dom'

import routes from './routes'
import StyleDetail from '../../StyleDetail'
import StylesGallery from '../StylesGallery'
import Homepage from '../Homepage'
import { api } from '../../../services'

export const elements = [
  {
    path: routes.root(),
    element: <Homepage />,
  },
  {
    path: routes.styles.index(),
    element: <StylesGallery />,
  },
  {
    path: routes.styles.show(':styleId'),
    element: <StyleDetail styleShow={api.styles.show} />,
  },
]

const router = createBrowserRouter(elements)

export default router
