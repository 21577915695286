import React, { useState } from 'react'
import { useAsync } from 'react-async-hook'

import classnames from 'classnames'
import { api, isLoading } from '../../../services'

function BannerTexts() {
  const [bannerTexts, setBannerTexts] = useState([])

  const getBannerTexts = async () => {
    const response = await api.bannerTexts.index()
    setBannerTexts(response.data.sort((a, b) => a.position - b.position))
    return response.data
  }

  const { status } = useAsync(getBannerTexts)

  if (isLoading(status)) {
    return <div />
  }

  return bannerTexts.map((bannerText) => (
    <div
      key={bannerText.id}
      className={
        classnames(
          'text-white py-2 px-1 text-xl',
          {
            'uppercase font-serif': bannerText.allCaps,
            'lowercase font-sans font-normal': !bannerText.allCaps,
          },
        )
      }
    >
      {bannerText.text}
    </div>
  ))
}

export default BannerTexts
