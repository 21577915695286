import React, { useState } from 'react'
import PropType from 'prop-types'
import { Link } from 'react-router-dom'
/* eslint-disable import/no-unresolved */
import 'swiper/css/thumbs'
import 'swiper/css'
/* eslint-enable import/no-unresolved */

import classNames from 'classnames'
import routes from '../../services/routes'

function Slide({ photo }) {
  const [hidden, setHidden] = useState(true)

  const handleLoad = () => setHidden(false)

  return (
    <swiper-slide
      class="px-2 h-1/2"
    >
      <Link
        to={routes.styles.show(photo.styleId)}
        type="button"
        className="relative"
      >
        <img
          className={classNames('', { hidden })}
          onLoad={handleLoad}
          src={photo.mediaSmall}
          alt={photo.accessibleDescription}
        />
        <div
          className="font-serif bg-white absolute opacity-75 pt-4 pb-4 text-center text-lg w-[96%] right-[2%] bottom-[1%]"
        >
          {photo.styleName}
        </div>
      </Link>
    </swiper-slide>
  )
}

Slide.propTypes = {
  photo: PropType.object.isRequired,
}

export default Slide
