import { isEmpty } from 'lodash'
import React, { useEffect, useState, useRef } from 'react'
import { useAsync } from 'react-async-hook'

import { api, isLoading } from '../../../services'
import InfiniteScroll from './InfiniteScroll'
import Style from './Style'
import { MD_BREAKPOINT } from './constants'

const DEFAULT_LG_ITEMS = 8
const DEFAULT_MED_ITEMS = 6

function StylesGallery() {
  const [styles, setStyles] = useState([])
  const [showLoader, setShowLoader] = useState(false)
  const [page, setPage] = useState(0)
  const [lastPage, setLastPage] = useState(-1)
  const windowSize = useRef(window.innerWidth)

  const numItems = () => {
    if (windowSize.current < MD_BREAKPOINT) {
      return DEFAULT_MED_ITEMS
    }
    return DEFAULT_LG_ITEMS
  }

  const getStyles = async () => {
    if (page + 1 <= lastPage || lastPage === -1) {
      const query = { shallow: true, page: page + 1, items: numItems() }
      const { data } = await api.styles.index(query)
      const newStyles = [...styles, ...data.styles]
      setStyles(newStyles)
      setPage(data.info.page)
      setLastPage(data.info.last)
    }
  }

  const { status } = useAsync(getStyles)

  useEffect(() => {
    if (!isEmpty(styles)) {
      const timer = setTimeout(() => {
        setShowLoader(true)
      }, 250)
      return () => clearTimeout(timer)
    }

    return () => { }
  }, [styles])

  return (
    <div className="flex flex-col items-center">
      <div className="flex flex-col items-center w-5/6">
        <h1 className="self-start text-xl py-4">Our Styles</h1>
        {
          isLoading(status) && (<div>Loading...</div>)
        }
        {
          !isLoading(status) && (
            <div className="flex flex-wrap">
              {
                styles.map((style) => (
                  <Style key={style.id} style={style} />
                ))
              }
              {
                showLoader
                && (
                <InfiniteScroll
                  loadMore={getStyles}
                  last={page === lastPage}
                />
                )
              }
            </div>
          )
        }
      </div>
    </div>
  )
}

export default StylesGallery
