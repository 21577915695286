import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import classnames from 'classnames'
import routes from '../services/routes'

/* put it in a black naza container on mobile */
function BookButton({ blackBorder, small }) {
  return (
    <Link
      to={routes.styles.index()}
      className={
        classnames(
          'inline-block',
          {
            'border-2 border-solid p-1': !small,
            'border-yellow': !blackBorder,
            'border-black': blackBorder,
          },
        )
      }
    >
      <div className={
        classnames(
          'bg-orange border-2 border-solid border-white inline-block',
          {
            'px-4 pt-[14px] pb-[11px] xl:px-6 xl:pt-6 xl:pb-4': !small,
            'px-2 pt-[10px] pb-[7px]': small,
          },
        )
      }
      >
        <div className={
          classnames(
            'inline text-white font-sans uppercase tracking-widest',
            {
              'text-sm': small,
              'text-med xl:text-lg 2xl:text-xlg': !small,
            },
          )
        }
        >
          Book an appointment
        </div>
      </div>
    </Link>
  )
}

BookButton.propTypes = {
  blackBorder: PropTypes.bool,
  small: PropTypes.bool,
}

export default BookButton
