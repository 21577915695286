import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { minutesToHours } from 'date-fns'
import { centsToDollars } from '../../../services'
import routes from '../services/routes'

function Style({ style }) {
  return (
    <div className="style w-full xs:w-1/2 md:w-1/3 lg:w-1/4 flex flex-col">
      <Link to={routes.styles.show(style.id)}>
        <img className="pr-4" alt={style.name} src={style.featured_photo?.mediaSmall} />
      </Link>
      <div className="py-4">
        <div>{style.name}</div>
        <div>{style.description_header}</div>
        <div>
          <span>
            $
            {centsToDollars(style.price)}
            +
          </span>
          {' '}
          |
          {' '}
          <span>
            {minutesToHours(style.duration)}
            {' '}
            hours
          </span>
        </div>
      </div>
    </div>
  )
}

Style.propTypes = {
  style: PropTypes.object.isRequired,
}

export default Style
